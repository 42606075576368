import React, { useEffect, useState } from "react";
import Back from "../common/back/Back";
import "./contact.css";
import { useRef } from "react";
import emailjs from "emailjs-com";
import { useFormik } from "formik";
import validate from "./Validation";

const onSubmit = async (values, actions) => {
  console.log("SUBMITTED");
  console.log(values);
  await new Promise((resolve) => setTimeout(resolve, 1500));
  actions.resetForm();
};

const Contact = () => {
  const [status, setStatus] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    handleSubmit();
    e.preventDefault();

    emailjs
      .sendForm(
        "service_oknyiku",
        "template_dybjiap",
        form.current,
        "0wCosTLGA3DVOQ7rc"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response);
          setStatus("SUCCESS");
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  useEffect(() => {
    if (status === "SUCCESS") {
      setTimeout(() => {
        setStatus("");
      }, 3000);
    }
  }, [status]);

  const {
    values,
    handleBlur,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: validate,
    onSubmit,
  });
  const map =
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1759.5467726147501!2d83.413684!3d28.113180000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4dfbb7e5df5f3fcf!2sChakreshwor%20English%20Boarding%20School!5e0!3m2!1sen!2snp!4v1665406778781!5m2!1sen!2snp";

  return (
    <section>
      <Back title="ContactUs" />
      <section className=" details-contact container">
        <div className="sec-head">
          <h1>Contact Us</h1>
        </div>
        <div className="contact">
          <div className="contact-mapSec">
            <div className="mapSec-list">
              <ul>
                <li>
                  <i class="fa-sharp fa-solid fa-map-location-dot"></i>
                  Chandrakot-4, Shantipur, Gulmi
                </li>
                <li>
                  <i class="fa-sharp fa-solid fa-phone"></i>+9857030938, +079
                  420009
                </li>
                <li>
                  <i class="fa-solid fa-envelope"></i>cebsgulmi60@gmail.com
                </li>
              </ul>
            </div>
            <div className="con-sec">
              <h3>Follow Us On</h3>
              <i class="fa-brands fa-square-facebook"></i>
              <i class="fa-brands fa-square-twitter"></i>
              <i class="fa-brands fa-square-instagram"></i>
            </div>
            <div>
              <iframe src={map}></iframe>
            </div>
          </div>

          <form
            ref={form}
            autoComplete="off"
            onSubmit={sendEmail}
            className="contact-form"
          >
            {status && renderAlert()}
            <div className="flexSB">
              <div className="formName">
                <input
                  required
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="firstName"
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  className={
                    errors.firstName && touched.firstName ? "input-error" : ""
                  }
                />
                {errors.firstName && touched.firstName && (
                  <p>{errors.firstName}</p>
                )}
              </div>
              <div className="formName">
                <input
                  required
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="lastName"
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  className={
                    errors.lastName && touched.lastName ? "input-error" : ""
                  }
                />
                {errors.lastName && touched.lastName && (
                  <p>{errors.lastName}</p>
                )}
              </div>
            </div>
            <input
              required
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              name="email"
              id="email"
              type="email"
              placeholder="Email"
              className={errors.email && touched.email ? "input-error" : ""}
            />
            {errors.email && touched.email && <p>{errors.email}</p>}
            <input
              required
              value={values.subject}
              onChange={handleChange}
              onBlur={handleBlur}
              id="subject"
              name="subject"
              type="text"
              placeholder="Subject"
              className={errors.subject && touched.subject ? "input-error" : ""}
            />
            {errors.subject && touched.subject && <p>{errors.subject}</p>}
            <textarea
              required
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              id="message"
              name="message"
              cols="30"
              rows="10"
              placeholder="Your Message Here..."
              className={errors.message && touched.message ? "input-error" : ""}
            />
            {errors.message && touched.message && (
              <p className="text-area-span">{errors.message}</p>
            )}

            <button
              disabled={isSubmitting}
              id="form-btn"
              className="btn-primary"
              type="submit"
            >
              SUBMIT
            </button>
          </form>
        </div>
      </section>
    </section>
  );
};

const renderAlert = () => (
  <div className="renderAlert">
    <span>your message submitted successfully</span>
  </div>
);

export default Contact;
