import React from 'react'
import { useLocation } from 'react-router-dom'

const Back = ({title}) => {

 const location = useLocation();

  return (
    <div>
        <section className='back'>
          <div className='back-text'>
            <h3>Home / {location.pathname.split("/")[1]}</h3>
            <h1>{title}</h1>
            </div>
        </section>
        <div className='margin-back'>

        </div>
    </div>
  )
}

export default Back