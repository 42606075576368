import React, { useState } from "react";

const NoticesAndEvents = (props) => {
  const [popup, setPopup] = useState(false);

  const toggleNotice = () => {
    setPopup(!popup);
  };
  return (
    <div>
      <div className="items">
        <div className="n-e-img">
          <img src={props.images} alt="" />
          <i className="fas fa-image" onClick={toggleNotice}>
            <span>Press Here</span>
          </i>
          <div>
            <div className="n-e-date">
              <h2>{props.day}</h2>
              <h4>{props.month}</h4>
            </div>
            <div className="n-e-desc">
              <h2>{props.title}</h2>
              <p>{props.desc}</p>
            </div>
          </div>
        </div>

        {popup && (
          <div className="popup">
            <div className="hide"></div>
            <div className="popup-content">
              <i
                style={{ opacity: "1" }}
                onClick={toggleNotice}
                class="fa-solid fa-rectangle-xmark"
              ></i>
              <img src={props.images} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NoticesAndEvents;
