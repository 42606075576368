import React from 'react'
import "./thadar.css"
import Typewriter from "typewriter-effect"
import { Link } from 'react-router-dom'

const Thadar = () => {
  return (
    <div>
      <section id="thadar_a" className='thadar container'>
        <div className='thadar-img'> </div>
        <div className='thadar-text'>
          <h2>Welcome to CEBS</h2>
          <h1>
          <Typewriter 
              options={{
                loop:true,
                delay:75,
              }}
              onInit={(typewriter)=>{
                typewriter.typeString("School of Commitment")
                .pauseFor(1500)
                .deleteChars(10)
                typewriter.typeString("Love and Care")
                .pauseFor(1500)
                .deleteChars(13)
                typewriter.typeString("Excellence!")
                .pauseFor(10000)
                .start();
              }}/>
          </h1>
          <p>A learning community dedicated to building respectful and responsible citizens and empowering all learners.</p>
          <div data-aos="fade-up" className='thadar-btn'>
          <Link to="/about">
            <button className='btn-primary'>
              Learn More<i class="fa-solid fa-arrow-right-long"></i>
            </button>
            </Link>
            <Link to="/contact">
            <button className='btn-secondary'>
              Contact Us<i class="fa-solid fa-arrow-right-long"></i>
            </button>
            </Link>
            </div>
        </div>
      </section>
    </div>
  )
}

export default Thadar