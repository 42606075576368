import React, { useState } from "react";
import { Component } from "react";
import Head from "./Head";
import { NavLink, Link } from "react-router-dom";
import "./header.css";

class Header extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  closeMobileMenu = () => {
    this.setState({
      clicked: false,
    });
  };
  scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  render() {
    return (
      <>
        <Head />
        <div className="header flexSB">
          <div className="logo  flexSB">
            <div className="logoCon" onClick={this.scrollToTop}>
              <Link to="/">
                <img src="../images/CEBS2.png" alt="logo" />
              </Link>
            </div>
            <div className="logo-title">
              <h2>Chakreshwor English</h2>
              <h2> Boarding School</h2>
              <h5>Established 2060 BS</h5>
            </div>
          </div>
          <div className="nav">
            <ul
              id="navBar"
              className={this.state.clicked ? "#navbar active" : "#navbar"}
              // className={mobile ? "nav-ul-mobile" : 'nav-ul'} onClick={()=> setMobile(false)}
            >
              <li>
                <NavLink
                  exact
                  to="/"
                  activeClassName="active-link"
                  onClick={this.closeMobileMenu}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to="/about"
                  activeClassName="active-link"
                  onClick={this.closeMobileMenu}
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to="/management-team"
                  activeClassName="active-link"
                  onClick={this.closeMobileMenu}
                >
                  Our Team
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to="/notices-events"
                  activeClassName="active-link"
                  onClick={this.closeMobileMenu}
                >
                  Notices&Events
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to="/gallery"
                  activeClassName="active-link"
                  onClick={this.closeMobileMenu}
                >
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to="/contact"
                  activeClassName="active-link"
                  onClick={this.closeMobileMenu}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>

          <div id="mobile" onClick={this.handleClick}>
            <i
              id="bars"
              className={
                this.state.clicked ? "fa-solid fa-xmark" : "fa-solid fa-bars"
              }
            />
          </div>
        </div>
      </>
    );
  }
}

window.addEventListener("scroll", () => {
  document
    .querySelector(".header")
    .classList.toggle("window-scroll", window.scrollY > 50);
  document
    .querySelector(".logo")
    .classList.toggle("logo-new", window.scrollY > 50);
  //  const elem= document.querySelectorAll(".link");
  //  for (var i=0; i<elem.length; i++){
  //   elem[i] .classList.toggle("link-new", window.scrollY>5);
  //  };

  // document.querySelector(".active-link").classList.remove("link-new", window.scrollY>5);
});

// const noticeColor= document.getElementById("notices");
// console.log(noticeColor);
// noticeColor.addEventListener("click", function onClick(event){
//   console.log(event.target);
//   event.target.style.backgroundColor="red";
// });
// const links = document.querySelectorAll(".nav span");
// console.log(links);

// links.forEach(span=>{
//   span.addEventListener("click", ()=>{
//     resetLinks();
//     span.className.add("active");
//   })
// })

// function resetLinks(){
//   links.forEach(span=>{
//     span.className.remove("active")
//   })
// }

export default Header;
