import React from 'react'
import Carousel from './carousel/Carousel'
import Counter from './counter/Counter'
import Intro from './intro/Intro'
// import NoticesEvents from './noticesEvents/NoticesEvents'
import Testimonials from './testimonials/Testimonials'
import Thadar from './thadar/Thadar'


const Home = () => {
  return (
    <div>
        <Thadar/>
        <Intro/>
        <Carousel/>
        {/* <NoticesEvents/> */}
        <Counter/>
        <Testimonials/>
    </div>
  )
}

export default Home