import * as Yup from "yup";

const namePattern = /[A-Za-z]+/;

const validate = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "First name should contain minimum of 3 characters!")
    .max(20, "First name shouldn't exceed 20 characters!")
    .matches(namePattern, {
      message:
        "Please input a valid name!",
    })
    .required("Please input your first name!"),
  lastName: Yup.string()
    .min(2, "Last name should contain minimum of 2 characters!")
    .max(25, "Last name shouldn't exceed 25 characters! ")
    .matches(namePattern, {
      message:
        "Please input a valid  last name!",
    })
    .required("Please input your last name!"),
  email: Yup.string()
    .email("please input a valid email")
    .required("Please input your email address!"),
  subject: Yup.string()
    .max(40, "Subject should be less than 40 characters!")
    .required("Please input a subject!"),
  message: Yup.string().required("Please input your message!"),
});

export default validate;
