import React from "react";
import {
  introduction,
  message,
  mission,
  objectives,
} from "../../data/about-d/aboutD";
import Back from "../common/back/Back";
import "./aboutUs.css";

const AboutUs = () => {
  return (
    <section>
      <Back title="About Us" />
      <section className="aboutUs container">
        <div className="sec-head">
          <h1>About Us</h1>
        </div>
        <div className="a-f">
          <div className="about-i ">
            {introduction.map((val, i) => (
              <div>
                <h1>{val.title}</h1>
                <p data-aos="fade-right">{val.desc}</p>
              </div>
            ))}
            <div className="h-l1"></div>
          </div>
          <div className="about-i messageSec">
            {message.map((val, i) => (
              <div>
                <h1>{val.title}</h1>
                <div className="prin-imgSec">
                  <img src={val.img} alt="" />
                  <div className="head-m">
                    <h3>Devendra Shah</h3>
                    <span>Principal</span>
                  </div>
                </div>
                <p data-aos="fade-right">{val.desc}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="h-l"></div>
        <div className="a-s">
          <div className="about-i">
            {mission.map((val, i) => (
              <div>
                <h1>{val.title}</h1>
                <p data-aos="fade-right">{val.desc}</p>
              </div>
            ))}
            <div className="h-l1"></div>
          </div>

          <div className="about-i objeSec">
            {objectives.map((val, i) => (
              <div>
                <h1>{val.title}</h1>
                <p data-aos="fade-right">{val.desc}</p>
                <ul data-aos="fade-right">
                  {/* <li>{val.p1}</li> */}
                  <li>{val.p2}</li>
                  <li>{val.p3}</li>
                  <li>{val.p4}</li>
                  <li>{val.p5}</li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
};

export default AboutUs;
