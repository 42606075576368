export const TestimonialD = [


    {
        image: "../images/testimonial-img/kamalsubedi.jpg",
        name: "Kamal Subedi",
        title: "कर अधिकृत",
        statement: "I am proud to share that I served CEBS for one and a half decades. I witnessed every up and down during the formative years. The small CEBS family has now grown larger and taking considerable strides toward becoming  a smart school. I wish the leading educational institution a great success ! Jaya CEBS!"
    },
    {
        image: "../images/testimonial-img/manojSubedi.jpg",
        name: " Lt Manoj Subedi",
        title: "Lieutenant",
        statement: <div style={{ lineHeight: "16.5px", wordSpacing: "0.1px" }}>Rooting its presence especially during the time of crisis, it has never compromised to deliver quality and sustainable education to the children from different villages in and around Shantipur valley. Provision of qualified and experienced teachers, updated syllabus, enough educational materials and sound learning environment have always led this institution to achieve commendable results in each Grade. I'm always grateful and in debt with this school for providing me basic level education which has been always shaping my educational career towards the remarkable heights Wishing for prosperity with my all best wishes. Proud to be CEBSian </div>
    },
    {
        image: "../images/testimonial-img/mohitkhatri.jpg",
        name: "Mohit Khatri",
        title: "Economics Undergraduate",
        statement: " 'Home'. It is how I feel about CEBS. Cheerful teachers, helpful friends, respectful seniors and loving juniors; they all harnessed my 8 years studying there. I am who I am because of CEBS. Proud to be a CEBSian."
    },
    {
        image: "../images/testimonial-img/sanjaysubedi1.jpg",
        name: "Sanjay Subedi",
        title: "Computer Science Undergraduate",
        statement: "The foundation for our future is built on the bedrock of great academic atmosphere. CEBS helped preparing and nourishing me to the fullest. I owe CEBS my success and want to acknowledge my respected teachers and seniors for their guidance and curious and hard-working  colleagues for their support. It is undoubtedly the one and only time period I have cherished, struggled and thrived the most during my student life."
    },







]