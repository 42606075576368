export const images = [
    "../images/img-gallery/newyear2080 (1).jpg",
    "../images/img-gallery/newyear2080 (2).jpg",
    "../images/img-gallery/newyear2080 (3).jpg",
    "../images/img-gallery/newyear2080 (4).jpg",
    "../images/img-gallery/newyear2080 (5).jpg",
    "../images/img-gallery/newyear2080 (6).jpg",
    "../images/img-gallery/newyear2080 (7).jpg",
    "../images/img-gallery/diwas (1).jpg",
    "../images/img-gallery/diwas (2).jpg",
    // "../images/img-gallery/diwas (3).jpg",
    "../images/img-gallery/diwas (4).jpg",
    "../images/img-gallery/diwas (5).jpg",
    "../images/img-gallery/diwas (6).jpg",
    "../images/img-gallery/diwas (7).jpg",
    "../images/img-gallery/diwas (8).jpg",
    "../images/img-gallery/runningShield (1).jpeg",
    "../images/img-gallery/runningShield (4).jpeg",
    "../images/img-gallery/runningShield (5).jpeg",
    "../images/img-gallery/runningShield (6).jpeg",
    "../images/img-gallery/runningShield (7).jpeg",
    "../images/img-gallery/runningShield (8).jpeg",
    "../images/img-gallery/runningShield (2).jpeg",
    "../images/img-gallery/runningShield (3).jpeg",
    "../images/img-gallery/runningShield (9).jpeg",
    "../images/img-gallery/runningShield (10).jpeg",
    "../images/img-gallery/runningShield (11).jpeg",
    "../images/img-gallery/runningShield (12).jpeg",
    "../images/img-gallery/anniversary(1).jpg",
    "../images/img-gallery/anniversary(2).jpg",
    "../images/img-gallery/anniversary(3).jpg",
    "../images/img-gallery/anniversary(4).jpg",
    "../images/img-gallery/anniversary(5).jpg",
    "../images/img-gallery/anniversary(6).jpg",
    "../images/img-gallery/anniversary(7).jpg",
    "../images/img-gallery/anniversary(8).jpg",
    "../images/img-gallery/anniversary(9).jpg",
    "../images/img-gallery/anniversary(10).jpg",
    "../images/img-gallery/anniversary(11).jpg",
    "../images/img-gallery/anniversary(12).jpg",
    "../images/img-gallery/anniversary(13).jpg",
    "../images/img-gallery/anniversary(16).jpg",
    "../images/img-gallery/anniversary(17).jpg",
    "../images/img-gallery/anniversary(18).jpg",
    "../images/img-gallery/anniversary(19).jpg",
    "../images/img-gallery/anniversary(20).jpg",
    "../images/img-gallery/anniversary(21).jpg",
    "../images/img-gallery/anniversary(22).jpg",
    "../images/img-gallery/anniversary(23).jpg",
    "../images/img-gallery/anniversary(24).jpg",
    "../images/img-gallery/anniversary(25).jpg",
    "../images/img-gallery/anniversary(26).jpg",
    "../images/img-gallery/anniversary(27).jpg",
    "../images/img-gallery/anniversary(28).jpg",
    "../images/img-gallery/anniversary(29).jpg",
    "../images/img-gallery/anniversary(30).jpg",
    "../images/img-gallery/anniversary(31).jpg",
    "../images/img-gallery/anniversary(32).jpg",
    "../images/img-gallery/anniversary(33).jpg",
    // "../images/img-gallery/anniversary(34).jpg",
    "../images/img-gallery/anniversary(35).jpg",
    "../images/img-gallery/anniversary(36).jpg",
    "../images/img-gallery/anniversary(37).jpg",
    "../images/img-gallery/anniversary(38).jpg",
    "../images/img-gallery/anniversary(39).jpg",
    "../images/img-gallery/anniversary(40).jpg",
    "../images/img-gallery/anniversary(42).jpg",
    "../images/img-gallery/anniversary(43).jpg",
    "../images/img-gallery/anniversary(46).jpg",
    "../images/img-gallery/anniversary(47).jpg",
    "../images/img-gallery/anniversary(48).jpg",
    "../images/img-gallery/anniversary(49).jpg",
    "../images/img-gallery/anniversary(50).jpg",
    "../images/img-gallery/anniversary(51).jpg",
    "../images/img-gallery/anniversary(52).jpg",
    "../images/img-gallery/anniversary(53).jpg",
    "../images/img-gallery/anniversary(54).jpg",
    "../images/img-gallery/anniversary(55).jpg",
    "../images/img-gallery/anniversary(56).jpg",
    "../images/img-gallery/anniversary(57).jpg",
    "../images/img-gallery/anniversary(58).jpg",
    "../images/img-gallery/anniversary(59).jpg",
    "../images/img-gallery/anniversary(60).jpg",
    "../images/img-gallery/anniversary(61).jpg",
    "../images/img-gallery/anniversary(62).jpg",
    "../images/img-gallery/anniversary(63).jpg",
    "../images/img-gallery/anniversary(64).jpg",
    "../images/img-gallery/anniversary(65).jpg",
    "../images/img-gallery/anniversary(66).jpg",
    "../images/img-gallery/anniversary(67).jpg",
    "../images/img-gallery/anniversary(68).jpg",
    "../images/img-gallery/anniversary(69).jpg",
    "../images/img-gallery/anniversary(70).jpg",
    "../images/img-gallery/anniversary(71).jpg",
    "../images/img-gallery/anniversary(72).jpg",
    "../images/img-gallery/anniversary(73).jpg",
    // "../images/img-gallery/anniversary(74).jPg",
    "../images/img-gallery/anniversary(75).jpg",
    "../images/img-gallery/anniversary(76).jpg",
    "../images/img-gallery/anniversary(77).jpg",
    "../images/img-gallery/anniversary(78).jpg",
    "../images/img-gallery/anniversary(79).jpg",
    "../images/img-gallery/anniversary(80).jpg",
]