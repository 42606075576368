export const head = [
    {
        img: "../images/team-img/devendraShah.jpeg",
        name: "Devendra Shah",
        title: "Principal",
        category: "head",
        fb: "https://m.me/devrajthakuri79",
        tel: "tel:9857030938",
        mail: "mailto:cebsgulmi60@gmail.com"
    },
    {
        img: "../images/team-img/purnaGautam.jpg",
        name: "Purna Gautam",
        title: "vice Principal/Exam Department Head",
        category: "head",
        fb: "https://m.me/",
        tel: "tel:9847375383",
        mail: "mailto:sita@yourapplicationdomain.com"
    },
    {
        img: "../images/team-img/ashokThapa.jpg",
        name: "Ashok Thapa",
        title: "ECA Head",
        category: "head",
        fb: "https://m.me/ashok.thapa.10048",
        tel: "tel:9844784534",
        mail: "mailto:ashok.ayush44@gmail.com"
    },
    {
        img: "../images/team-img/RamPrasadKharel.jpg",
        name: "Ram Prasad Kharel",
        title: "Account Department Head",
        category: "head",
        fb: "https://m.me/ram.prasadkharel.75",
        tel: "tel:9857036241",
        mail: "mailto:rpkharel76@gmail.com"
    },
    {
        img: "../images/team-img/binodSubedi.jpg",
        name: "Binod Subedi",
        title: "Computer and Technology Head",
        category: "head",
        fb: "https://m.me/binod.subedi.92",
        tel: "tel:9847432344",
        mail: "mailto:binodsubedi14@gamil.com",
    },

    {
        img: "../images/team-img/sunilgautam.jpg",
        name: "Sunil Gautam",
        title: " Secondary Science Teacher",
        category: "house",
        fb: "https://m.me/sonealg12",
        tel: "tel:9843467001",
        mail: "mailto:sunilgautam932@gmail.com"
    },
    {
        img: "../images/team-img/mayaKhatri.jpg",
        name: "Maya Khatri",
        title: "Secondary Level Teacher",
        category: "admin",
        fb: "https://m.me/maya.khatri.393",
        tel: "tel:9841202548",
        mail: "mailto:mayakhatri1379@gmail.com"
    },

    {
        img: "../images/team-img/khimaGharti.jpg",
        name: "Khima Gharti",
        title: "Basic Level Incharge",
        category: "head",
        fb: "https://m.me/r",
        tel: "tel:9867587575",
        mail: "mailto:khimagharti@gmail.com"
    },

    {
        img: "../images/team-img/ganghaGharti.jpg",
        name: "Ganga Gharti",
        title: "Science Teacher",
        fb: "https://m.me/ganga.gharti.50",
        tel: "tel:9866576731",
        mail: "mailto:sarkiganga576@gamil.com",

    },

    {
        img: "../images/team-img/punamBhandari.jpg",
        name: "Punam Bhandari",
        title: "Primary Level Teacher",
        category: "house",
        fb: "https://m.me/binod.subedi.92",
        tel: "tel:9857079646",
        mail: "mailto:pr3252111@gamil.com",
    },
    {
        img: "../images/team-img/minaPoudel.jpg",

        name: "Mina Thapa",
        title: "Secondary Level Teacher",
        category: "house",
        fb: "https://m.me/mina.poudel.3367",
        tel: "tel:9843679061",
        mail: "mailto:meenabibek5@gamil.com",
    },


    {
        img: "../images/team-img/faudarThapa.jpg",
        name: " Kamala Phaudar",
        title: "Primary Level Teacher",
        fb: "https://m.me/",
        tel: "tel:9898989898",
        mail: "mailto:faudarkamla@gamil.com",
    },
    {
        img: "../images/team-img/nirutaThapa.jpg",
        name: "Niruta Thapa",
        title: "Primary Level Teacher",
        fb: "https://m.me/100047128175465",
        tel: "tel:9845326753",
        mail: "mailto:nirutathapa67@gamil.com",
    },
    {
        img: "../images/team-img/rumaPun.jpg",
        name: "Ruma Magar",
        title: "Pre Primary Incharge",
        category: "head",
        fb: "https://m.me/100004406749216",
        tel: "tel:9849139112",
        mail: "mailto:rumamagar941@gamil.com",
    },
    {
        img: "../images/team-img/sujataShrestha.jpg",
        name: "Sujata Shrestha",
        title: "Primary Level Teacher",
        fb: "https://m.me/100015097638691",
        tel: "tel:9867231852",
        mail: "mailto:rc477653@gamil.com",
    },
    {
        img: "../images/team-img/amritaPun.jpg",
        name: "Amrita Pun",
        title: "Primary Level Teacher",
        fb: "https://m.me/1000150976",
        tel: "tel:9867018494",
        mail: "mailto:adrinamrita44@gamil.com",
    },
    {
        img: "../images/team-img/sitaSubedi.jpg",
        name: "Sita Subedi",
        title: "Mentor Eco Club Head",
        category: "head",
        fb: "https://m.me/100015097691",
        tel: "tel:9867754196",
        mail: "mailto:rc453@gamil.com",
    },
    {
        img: "../images/team-img/pusphaBashyal.jpg",
        name: "Puspa Bashyal",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/100015097638691",
        tel: "tel:9844794328",
        mail: "mailto:rc477653@gamil.com",
    },

    {
        img: "../images/team-img/bishnuChhetri.jpg",
        name: "Bishnu Chhetri",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/bishnu.chhetri.75033",
        tel: "tel:9843377422",
        mail: "mailto:bishnu999chhetri@gmail.com",

    },

    {
        img: "../images/team-img/saritaSharma.jpg",
        name: "Sarita Sharma",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/sarita.aryal.9461",
        tel: "tel:9867217632",
        mail: "mailto:aryalsarita555@gmail.com",


    },

    {
        img: "../images/team-img/sunaKarki.jpg",
        name: "Suna Karki",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/suna.karki.98",
        tel: "tel:9867579939",
        mail: "mailto:sunakarki909@gmail.com",


    },
    {
        img: "../images/team-img/janakiKharel.jpg",
        name: "Janaki Kharel",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/su..98",
        tel: "tel:9847582117",
        mail: "mailto:sunakar9@gmail.com",


    },

    {
        img: "../images/team-img/nirmalaThapa.jpg",
        name: "Nirmala Thapa",
        title: "Accountant",
        category: "head",
        fb: "https://m.me/su.k98",
        tel: "tel:9866562408",
        mail: "mailto:poudelmeena93@gmail.com",


    },
    {
        img: "../images/team-img/junaThapaGurung.jpg",
        name: "Juna Thapa Gurung",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/su..98",
        tel: "tel:9867025365",
        mail: "mailto:junat135@gmail.com",


    },


    {
        img: "../images/team-img/pujaShah.jpg",
        name: "Puja Shah",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/su.98",
        tel: "tel:9843227406",
        mail: "mailto:juna5@gmail.com",
    },


    {
        img: "../images/team-img/dikshaPoudel.jpg",
        name: "Diksha Poudel",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/su.98",
        tel: "tel:9867527124",
        mail: "mailto:dikshapoudel7@gmail.com",
    },

    {
        img: "../images/team-img/mayaGurung.jpg",
        name: "Maya Gurung",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/.a.98",
        tel: "tel:9847010215",
        mail: "mailto:mayapun231@gmail.com",
    },

    {
        img: "../images/team-img/laxmiKhatri.jpg",
        name: "Laxmi Khatri",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/su.98",
        tel: "tel:9847796087",
        mail: "mailto:khatrismriti231@gmail.com",
    },

    {
        img: "../images/team-img/sabitraThapa.jpg",
        name: "Sabitra Thapa",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.meu.98",
        tel: "tel:9867518711",
        mail: "mailto:sabitrathapa9937@gmail.com",
    },

    {
        img: "../images/team-img/saralaGaire.jpg",
        name: "Sarala Gaire",
        title: "Primary Level Teacher",
        category: "secondary",
        fb: "https://m.me/su.98",
        tel: "tel:9867585045",
        mail: "mailto:saralagaire27@gmail.com",
    },

    {
        img: "../images/team-img/krishnaPokhrel.jpg",
        name: "Krishna Pokhrel",
        title: "Maintenance Staff",
        category: "staff",
        fb: "https://m.meu.98",
        tel: "tel:079-420009",
        mail: "mailto:cebsgulmi60@gmail.com",
    },

    {
        img: "../images/team-img/sitaRana.jpg",
        name: "Sita Rana",
        title: "Maintenance Staff",
        category: "staff",
        fb: "https://m.meu.98",
        tel: "tel:079-420009",
        mail: "mailto:cebsgulmi60@gmail.com",
    },


]