import React from 'react'
import { NoticesEventsD } from '../../data/notices-events-d/notice-events-d'
import Back from '../common/back/Back'
import "./noticesAndEvents.css"
import Card from "./Card"

const NoticesAndEvents = () => {
  return (
    <section>
         <Back title="Notices&Events"/>
         <section className='notices-and-events container'>
          <div className="sec-head">
            <h1>Notices & Events</h1>
            </div>
            <div className='notices-events'>
                {NoticesEventsD.map((value)=>{
                    return <Card images={value.image} day={value.day} month={value.month} title={value.title} desc={value.desc}/>
                })}
            </div>
            </section>
    </section>
  )
}

export default NoticesAndEvents