import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

var year = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="footer-con">
      <div className="footer">
        <div className="foot-first">
          <h1>CEBS</h1>
          <span>Scaling The Remarkable Heights...</span>

          <p>
            Chakreshwor English Boarding School (CEBS) has been serving quality
            and comprehensive education to the children since its establishment
            in 2060 BS.
          </p>
        </div>
        <div className="foot-second">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <i class="fa-solid fa-play"></i>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <i class="fa-solid fa-play"></i>
              <Link to="/management-team">Team</Link>
            </li>
            <li>
              <i class="fa-solid fa-play"></i>
              <Link to="/notices-events">Notices</Link>
            </li>
            <li>
              <i class="fa-solid fa-play"></i>
              <Link to="/gallery">Gallery</Link>
            </li>
            <li>
              <i class="fa-solid fa-play"></i>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="foot-third">
          <h3>Have a questtion?</h3>
          <ul>
            <li>
              {" "}
              <i class="fa-solid fa-location-dot"></i>
              Chandrakot-4,Gulmi
            </li>

            <li>
              <i class="fa-solid fa-phone"></i>
              <a
                style={{ fontWeight: "400", color: "rgb(82, 80, 80)" }}
                href="tel:079-420009"
              >
                079-420009
              </a>
              ,{"  "}
              <a
                style={{ fontWeight: "400", color: "rgb(82, 80, 80)" }}
                href="tel:9857030938"
              >
                9857030938
              </a>
            </li>

            <li>
              <i class="fa-solid fa-envelope"></i>
              <a
                style={{ fontWeight: "400", color: "rgb(82, 80, 80)" }}
                href="mailto:cebsgulmi60@gmail.com"
              >
                cebsgulmi60@gmail.com
              </a>
            </li>
          </ul>
          <h2>-Follow us on: </h2>
          <div className="follow-foot">
            <a href="https://www.facebook.com/CEBS.Shantipur/" target="_blank">
              <i class="fab fa-facebook-f icon"></i>
            </a>
            <i className="fab fa-twitter icon"></i>
            <i className="fab fa-instagram icon"></i>
          </div>
        </div>
      </div>
      <div className="copyright">
        <span className="span-f">
          Copyright © {year} All rights reserved CEBS
        </span>
        <span className="span-s">Pawan Thapa(FK)</span>
      </div>
    </div>
  );
};

export default Footer;
