import React, { useEffect, useState } from 'react'

const GoToTopBtn = () => {

    const [backToTop, setBackToTop] =useState(false);

   useEffect(()=>{
window.addEventListener("scroll",()=>{
    if(window.scrollY>200){
        setBackToTop(true)
    }else{
        setBackToTop(false)
    }
})
   },[])

    const scrollToTop=()=>{
        window.scrollTo({top:0, left:0, behaviour:"smooth"});
    }
  return (
    <>
    {backToTop &&(
        <div className='scrollUp-btn' onClick={scrollToTop}><i class="fa-solid fa-arrow-up"></i></div>
    )}
    
    </>
  )
}

export default GoToTopBtn