export const introduction = [
    {
        title: "Introduction",
        desc: <div><p style={{ lineHeight: "18px", fontSize: "15px" }}>Chakreshwor English Boarding School (CEBS) has been serving quality and comprehensive education to the children since its establishment in 2060 BS.
            Our institution plays a cascading role in the career and personality of innumerable students. With the help of professional and experienced teachers, our school never fails to achieve top positions in every academic  results and extracurricular programs.</p>
            <p style={{ margin: "1px 0", lineHeight: "18px", fontSize: "15px" }}> Our students come to us from different socio-political, economical, cultural background, but they share common bonds: they value learning, respect each other and appreciate the skills and expertise of their teachers and friends.
                We strive not only to develop the sense of love for social service,
                discipline, compassion for nature, agility and awareness for one’s rights
                and duties, pride for Nepalese tradition and culture, but also make them
                good human beings and confident leaders.</p>
            <p style={{ lineHeight: "18px", fontSize: "15px" }}>
                The journey of CEBS would lead our students towards
                turning out to be the best citizens endowed with humanistic values. Our guidance and education helps each child  recognize their hidden talents.  Our school is recognized with a motto of educational development of its locality and being reputed nationally.
            </p></div>

    }]

export const message = [

    {
        title: "Message From The Head",
        img: "../images/prinabout.jpg",
        desc: <div><p style={{ margin: "5px 0", lineHeight: "18px", fontSize: "15px" }}>It is both my pleasure and privilege to welcome you to CEBS, a school with a new vision. I would like to extend our sincere gratitude to all who put their trust upon us. I believe students are individuals with rights and responsibilities therefore; we assist students to progressively accept more responsibility for self-discipline, to develop self respect, to contribute to the community, to develop a love of learning, and to strive for excellence.
        </p><p style={{ margin: "4px 0", lineHeight: "18px", fontSize: "15px" }}>
                I intently believe that our students will have an all-round development of their personality during their study in CEBS, the Saraswati temple of learning.

                I'm confident we will be able to provide our students a blissful and successful life. We help our students have ambitions and aims untrammelled
                and our students' ; and guardians' ; hard work, enthusiasm would take the
                students to any extent they desire. Getting formally instructed from this institution would open new horizons of development and bliss for the country.
                With best wishes
            </p></div>
    },]

export const mission = [

    {
        title: "Mission",
        desc: <div><p>Our mission is to provide all students with the opportunity to perform to their personal best and to develop important life skills as good citizens and leaders in our global society. </p>
            <p style={{ margin: "10px 0" }}>The learning environment at CEBS emphasizes the global future that our students will be a part of. Increasingly, they will interact globally to innovate, solve problems, communicate and collaborate with people from different cultures all over the world.</p></div>


    },]

export const objectives = [

    {
        title: "Objectives",
        desc: "",
        p2: "To foster holistic developments of students.",
        p3: "To bring out the potentiality of the students.",
        p4: "To provide the students conductive environment for their complete nurture.",
        p5: "To develop skills in students to be responsible creators than job seekers.",
        p6: "To meet global demand and competition through education.",




    }
]