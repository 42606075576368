import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Home from './components/home/Home';
import Header from './components/common/header/Header';
import ScrollToTop from './components/common/ScrollToTop';
import GoToTopBtn from './components/common/GoToTopBtn';
import Footer from './components/common/footer/Footer';
import AboutUs from './components/aboutUs/AboutUs';
import Team from './components/team/Team';
import Contact from './components/contactUs/Contact';
import NoticesAndEvents from "./components/noticesAndEvents/NoticesAndEvents";
import AOS from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";
import ImageGallery from "./components/gallery/ImageGallery";

function App() {

  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <>

      <Router>
        <ScrollToTop />
        <div>

          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <AboutUs />
            </Route>
            <Route exact path="/management-team">
              <Team />
            </Route>
            <Route exact path="/notices-events">
              <NoticesAndEvents />
            </Route>
            <Route exact path="/gallery">
              <ImageGallery />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>


          </Switch>
        </div>
        <GoToTopBtn />
        <Footer />
      </Router>
    </>
  );
}

export default App;
