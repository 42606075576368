import React, { useState } from "react";
import { head } from "../../data/teams-d/teamsD";
import Back from "../common/back/Back";
import "./team.css";

const Team = () => {
  const [list, setList] = useState(head);

  const filterItem = (currentItem) => {
    // handleClick();
    const newItem = head.filter((yitem) => {
      return yitem.category === currentItem;
    });

    setList(newItem);

    if (currentItem === "all") {
      setList(head);
    }
  };

  return (
    <section>
      <Back title="Our Team" />
      <section className="team container">
        <div className="sec-head">
          <h1>Meet Our Team</h1>
        </div>
        <div className="team-btn">
          <button className="btn-secondary" onClick={() => filterItem("all")}>
            All
          </button>
          <button className="btn-secondary" onClick={() => filterItem("head")}>
            Adminstration
          </button>

          <button className="btn-secondary" onClick={() => filterItem("staff")}>
            Maintenance Staff
          </button>
          {/* <button
            className="btn-secondary"
            onClick={() => filterItem("Female")}
          >
            Female
          </button> */}
          {/* <button
            className="btn-secondary"
            onClick={() => filterItem("primary")}
          >
            Primary
          </button> */}
        </div>
        <div className="t-con">
          {list.map((val, i) => (
            <div data-aos="flip-left" className="profile t1">
              <img src={val.img} alt="" />
              <h3>{val.name}</h3>
              <p>{val.title}</p>
              <div className="team-socials">
                <a href={val.fb} target="_blank">
                  <i class="fa-brands fa-facebook-messenger"></i>
                </a>

                <a href={val.tel}>
                  <i class="fa-solid fa-phone"></i>
                </a>
                <a href={val.mail}>
                  <i class="fa-solid fa-envelope"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </section>
  );
};

export default Team;
