import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./counter.css";

const Counter = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      // onExit={()=> setCounterOn(false)}
    >
      <div>
        <section className="counter count-container">
          <div className="counter-com" data-aos="zoom-in">
            <i class="fa-solid fa-person-chalkboard"></i>
            <h1>{counterOn && <CountUp start={0} duration={3} end={30} />}+</h1>
            <h4>Mentors</h4>
          </div>

          <div className="counter-com" data-aos="zoom-in">
            <i class="fa-solid fa-people-group"></i>
            <h1>{counterOn && <CountUp start={0} duration={3} end={10} />}+</h1>
            <h4>Students Organizations</h4>
          </div>

          <div className="counter-com" data-aos="zoom-in">
            <i class="fa-solid fa-puzzle-piece"></i>
            <h1>{counterOn && <CountUp start={0} duration={3} end={90} />}+</h1>
            <h4>ECA per year</h4>
          </div>
          <div className="counter-com" data-aos="zoom-in">
            <i class="fa-sharp fa-solid fa-graduation-cap"></i>

            <h1>
              {counterOn && <CountUp start={0} duration={3} end={560} />}+
            </h1>
            <h4>Students</h4>
          </div>
        </section>
      </div>
    </ScrollTrigger>
  );
};

export default Counter;
