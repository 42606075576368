import React from "react";
import { TestimonialD } from "../../../data/testimonial-d/testimonial";
import "./testimonials.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";

const Testimonials = () => {
  return (
    <div>
      <section className="testimonials container">
        <div className="testimonials-head">
          <h2>Testimonials</h2>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          loop={true}
          autoplay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            480: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {TestimonialD.map((val, i) => (
            <SwiperSlide>
              <div className="test-con">
                <div className="test-up">
                  <p>{val.statement}</p>
                  <i class="fa-solid fa-quote-right"></i>
                </div>
                <div className="test-down">
                  <div className="test-img">
                    <img src={val.image} alt="" />
                  </div>
                  <div className="test-img-desc">
                    <h3>{val.name}</h3>
                    <h4>{val.title}</h4>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
};

export default Testimonials;
