import React from "react";
import { Link } from "react-router-dom";
import "./intro.css";

const Intro = () => {
  return (
    <div>
      <section className="intro container">
        <div data-aos="fade-right" className="about-school flexSB">
          <div className="img">
            <img src="../images/schoolintrohome.jpg" alt="school" />
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="school-description"
          >
            <h2>CEBS</h2>
            <p>
              Chakreshwor English Boarding School (CEBS) has been serving
              quality and comprehensive education to the children since its
              establishment in 2060 BS. Our institution plays a cascading role
              in the career and personality of innumerable students.
            </p>
            <Link to="/about">
              <button className="btn-primary">Read More</button>
            </Link>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2500"
          className="principal-section "
        >
          <div className="principal-img">
            <img src="../images/principal.jpg" alt="principal" />
          </div>
          <div className="principal-word">
            <h2>Devendra Shah</h2>
            <h3>Principal</h3>
            {/* <h4>"Lorem ipsum dolor sit amet consectetur"</h4> */}
            <p>
              It is both my pleasure and privilege to welcome you to CEBS, a
              school with a new vision. I would like to extend our sincere
              gratitude to all who put their trust upon us.
            </p>
            <Link to="/about">
              <button className="btn-primary">Read More</button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
