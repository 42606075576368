import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import "./carousel.css";

const Carousel = () => {
  return (
    <div>
      <section className="carousel ">
        <div className="parent">
          <div className="middle-design">
            <div className="mdl-text-sec">
              <h1>An Investment In Knowledge</h1>
              <h1>pays the best</h1>
              <h1> interest!!!</h1>
              <h3>CEBS, Chandrakot-4, Gulmi</h3>
            </div>
          </div>
        </div>
        <div className="carousel-design">
          <Swiper
            slidesPerView={2}
            spaceBetween={0}
            slidesPerGroup={1}
            loop={false}
            loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            speed={1500}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              780: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
            }}
          >
            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/batch.jpg" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/leader.jpg" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/13.jpeg" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/11.jpeg" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/10.jpeg" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/1.jpg" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/2.jpg" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/3.jpg" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/4.jpg" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/6.jpg" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/7.jpg" alt="" />
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="carousel-img">
                <img src="../images/carousel-img/8.jpg" alt="" />
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default Carousel;
