import React from "react";
import "./header.css";

const Head = () => {
  return (
    <div>
      <div className="head flexSB">
        <div className="head-left flexSB">
          <i class="fa-solid fa-location-dot"></i>
          <h3>Chandrakot-4, Gulmi</h3>

          <i class="fa-solid fa-envelope"></i>
          <h3>
            <a href="mailto:cebsgulmi60@gmail.com">cebsgulmi60@gmail.com</a>
          </h3>

          <i class="fa-sharp fa-solid fa-phone"></i>
          <h3>
            <a href="tel:079-420009">079-420009</a>,{"  "}
            <a href="tel:9857030938">9857030938</a>
          </h3>
        </div>
        <div className="head-right">
          <a href="https://www.facebook.com/CEBS.Shantipur/" target="_blank">
            <i class="fa-brands fa-facebook"></i>
          </a>
          <i class="fa-brands fa-youtube"></i>
          <i class="fa-brands fa-facebook-messenger"></i>
        </div>
      </div>
    </div>
  );
};

export default Head;
