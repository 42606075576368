import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { images } from "../../data/image-gallery/image-gallery";
import Back from "../common/back/Back";
import "./imageGallery.css";

const ImageGallery = () => {
  const [data, setData] = useState({ img: "", i: 0 });

  const viewImage = (img, i) => {
    setData({ img, i });
  };

  const imgAction = (action) => {
    let i = data.i;
    if (action === "next-img") {
      setData({ img: images[i + 1], i: i + 1 });
    }
    if (action === "prev-img") {
      setData({ img: images[i - 1], i: i - 1 });
    }
    if (!action) {
      setData({ img: "", i: 0 });
    }
  };

  return (
    <section>
      {data.img && (
        <div className="main-bg">
          <div className="close-btn">
            <i
              onClick={() => imgAction()}
              class="fa-solid fa-rectangle-xmark"
            ></i>
          </div>
          <i
            style={{
              position: "absolute",
              left: "10px",
              color: "rgba(255,255,255,0.7)",
            }}
            onClick={() => imgAction("prev-img")}
            class="fa-solid fa-arrow-left"
          ></i>
          <img className="pop-up" src={data.img} />
          <i
            style={{
              position: "absolute",
              right: "10px",
              color: "rgba(255,255,255,0.7)",
            }}
            onClick={() => imgAction("next-img")}
            class="fa-sharp fa-solid fa-arrow-right"
          ></i>
        </div>
      )}
      <Back title="Gallery" />
      <section style={{ paddingRight: "10px", paddingLeft: "10px" }}>
        <div className="sec-head">
          <h1>Gallery</h1>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry gutter="11px">
              {images.map((image, i) => (
                <img
                  key={i}
                  src={image}
                  style={{ width: "100%", display: "block", cursor: "pointer" }}
                  alt=""
                  onClick={() => viewImage(image, i)}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </section>
    </section>
  );
};

export default ImageGallery;
