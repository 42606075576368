export const NoticesEventsD = [
    {
        day: "1",
        month: "Chaitra",
        image: "../images/notices-events/ne11.jpg",
        title: "Annual Examination Routine",
        // desc: "hello in th e worlello in th e worlello in th e worlello in th e world"
    },
    {
        day: "16",
        month: "Falgun",
        image: "../images/notices-events/ne10.jpg",
        title: "GLE / BLE Examination Routine",
        // desc: "hello in th e worlello in th e worlello in th e worlello in th e world"
    },
    {
        day: "12",
        month: "Magh",
        image: "../images/notices-events/ne8.jpg",
        title: "अक्षरारम्भ कार्यक्रम",
        // desc: "hello in th e worlello in th e worlello in th e worlello in th e world"
    },
    {
        day: "10",
        month: "Magh",
        image: "../images/notices-events/ne9.jpg",
        title: "20th Anniversary/Sports Day 2079",
        // desc: "hello in th e worlello in th e worlello in th e worlello in th e world"
    },
    {
        day: "24",
        month: "Poush",
        image: "../images/notices-events/ne7.jpg",
        title: "Result Publication",
        // desc: "hello in th e worlello in th e worlello in th e worlello in th e world"
    },
    {
        day: "7",
        month: "Poush",
        image: "../images/notices-events/ne6.jpeg",
        title: "Half Yearly Examination",
        // desc: "hello in th e worlello in th e worlello in th e worlello in th e world"
    },
    {
        day: "8",
        month: "Mangshir",
        image: "../images/notices-events/ne1.jpg",
        title: "Saturday Test",
        //  desc:"Grade 5, 8 and 10 "
    },
    {
        day: "8",
        month: "Mangshir",
        image: "../images/notices-events/ne2.jpg",
        title: "Home Test",
        // desc: "hello in th e worlello in th e worlello in th e worlello in th e world"
    },
    {
        day: "6",
        month: "Karktik",
        image: "../images/notices-events/ne3.jpeg",
        title: "Tihar Vacation",
        // desc: "hello in th e worlello in th e worlello in th e worlello in th e world"
    },
    {
        day: "2",
        month: "Karktik",
        image: "../images/notices-events/ne4.jpeg",
        title: "Educational Tour",
        // desc: "hello in th e worlello in th e worlello in th e worlello in th e world"
    }

]